import { useQueryString } from '@kaliber/use-query-string'
import { useMediaQuery } from '@kaliber/use-media-query'

import { useNormalizedTechblogFilterValues } from '/machinery/useNormalizedFilterValues'
import { updateFilterValue } from '/machinery/updateFilterValue'
import { trackFilterInteractionMultiValue } from '/analytics/user-interaction'

import { Icon } from '/features/buildingBlocks/Icon'

import mediaStyles from '/cssGlobal/media.css'
import styles from './SelectableCardFilter.css'

import iconCheck from '/images/icons/check.raw.svg'

export function SelectableCardFilter({ filter }) {
  const [queryString, setQueryString] = useQueryString()
  const value = useNormalizedTechblogFilterValues(queryString)[filter.id]

  const optionsSorted = React.useMemo(
    () => filter.options.sort((a, b) => a.display_order - b.display_order),
    [filter]
  )

  return (
    <>
      {optionsSorted.map((option, i) => (
        <SelectableCard
          id={option.id}
          key={option.id}
          label={option.label}
          subtitle={option.subtitle}
          onChange={() => handleFilterChange(filter.id, option.id)}
          checked={value.includes(option.id)}
        />
      ))}
    </>
  )

  function handleFilterChange( filterId, optionId ) {
    const updatedFilterValue = updateFilterValue({ value, filterId, optionId })

    trackFilterInteractionMultiValue({
      name: filterId,
      value: optionId,
      selected: updatedFilterValue.includes(optionId),
      inputType: 'multi_select',
    })

    setQueryString(x => ({ ...x, ...{ [filterId]: updatedFilterValue }, page: null }))
  }
}

function SelectableCard({ id, label, subtitle, onChange, checked }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)

  return (
    <div className={cx(styles.componentSelectableCard, checked && styles.isChecked)}>
      <input
        type='checkbox'
        data-x='apply-filter'
        className={styles.input}
        {...{ onChange, checked, id }}
      />

      <div className={styles.textContainer}>
        <label htmlFor={id} className={styles.label}>{label}</label>

        {isViewportLg && subtitle && (
          <div className={styles.subitleContainer}>
            <span className={styles.subtitle}>{subtitle}</span>
          </div>
        )}
      </div>

      <span className={cx(styles.iconContainer, checked && styles.isChecked)}>
        <Icon icon={iconCheck} />
      </span>
    </div>
  )
}
