import { QueryStringProvider } from '@kaliber/use-query-string'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import { SelectableCardFilter } from '/features/buildingBlocks/SelectableCardFilter'

const client = new QueryClient()

export default function SelectableCardFilterApp({ search, filter }) {
  return (
    <QueryStringProvider {...{ search }}>
      <QueryClientProvider {...{ client }}>
        <SelectableCardFilter {...{ filter }} />
      </QueryClientProvider>
    </QueryStringProvider>
  )
}
