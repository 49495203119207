export function ensureFloat(valueRaw, defaultValue) {
  const value = parseFloat(valueRaw)
  return isNaN(value) ? defaultValue : value
}

export function ensureInteger(valueRaw, defaultValue) {
  const value = parseInt(valueRaw, 10)
  return isNaN(value) ? defaultValue : value
}

export function ensureString(value, defaultValue = '') {
  if (!value || Array.isArray(value)) return defaultValue
  return String(value)
}

export function ensureArrayWithValues(value) {
  return removeEmptyValuesFromArray(ensureArray(value))
}

function ensureArray(value) {
  if (!Array.isArray(value)) return []
  return value
}

function removeEmptyValuesFromArray(value) {
  return value.filter(isNotEmptyString)
}

function isNotEmptyString(value) {
  return value && typeof value === 'string'
}
