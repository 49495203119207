import { ensureInteger, ensureString, ensureArrayWithValues } from '/machinery/ensureValue'

export function useNormalizedJobFilterValues(queryString) {
  return React.useMemo(
    () => ({
      page: ensureInteger(queryString.page, 1),
      q: ensureString(queryString.q),
      country: ensureString(queryString.country),
      remote_type: ensureArrayWithValues(queryString.remote_type),
      contract_type: ensureArrayWithValues(queryString.contract_type),
      job_branch: ensureArrayWithValues(queryString.job_branch),
      sub_expertise: ensureArrayWithValues(queryString.sub_expertise),
    }),
    [queryString]
  )
}

export function useNormalizedTechblogFilterValues(queryString) {
  return React.useMemo(
    () => ({
      page: ensureInteger(queryString.page, 1),
      category: ensureArrayWithValues(queryString.category),
      tag: ensureArrayWithValues(queryString.tag),
    }),
    [queryString]
  )
}

export function useNormalizedTopicsFilterValues(queryString) {
  return React.useMemo(
    () => ({
      page: ensureInteger(queryString.page, 1),
      tag: ensureArrayWithValues(queryString.tag),
      q: ensureString(queryString.q),
    }),
    [queryString]
  )
}
